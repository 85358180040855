var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.lazy || _vm.active
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active,
              expression: "active",
            },
          ],
          staticClass: "wizard-tab-container",
          attrs: {
            role: "tabpanel",
            id: _vm.tabId,
            "aria-hidden": !_vm.active,
            "aria-labelledby": "step-" + _vm.tabId,
          },
        },
        [_vm._t("default", null, { active: _vm.active })],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }