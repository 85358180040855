var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-form-wizard",
      class: [_vm.stepSize, { vertical: _vm.isVertical }],
      attrs: { id: _vm.id ? _vm.id : "" },
      on: {
        keyup: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "right", 39, $event.key, [
                "Right",
                "ArrowRight",
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 2) {
              return null
            }
            return _vm.focusNextTab.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft",
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 0) {
              return null
            }
            return _vm.focusPrevTab.apply(null, arguments)
          },
        ],
      },
    },
    [
      _vm.$slots["title"]
        ? _c(
            "div",
            { staticClass: "wizard-header" },
            [
              _vm._t("title", function () {
                return [
                  _c("h4", { staticClass: "wizard-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c("p", { staticClass: "category" }, [
                    _vm._v(_vm._s(_vm.subtitle)),
                  ]),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "wizard-navigation" }, [
        !_vm.isVertical
          ? _c("div", { staticClass: "wizard-progress-with-circle" }, [
              _c("div", {
                staticClass: "wizard-progress-bar",
                style: _vm.progressBarStyle,
              }),
            ])
          : _vm._e(),
        _c(
          "ul",
          {
            staticClass: "wizard-nav wizard-nav-pills",
            class: _vm.stepsClasses,
            staticStyle: { "align-items": "center" },
            attrs: { role: "tablist" },
          },
          [
            _vm._l(_vm.tabs, function (tab, index) {
              return _vm._t(
                "step",
                function () {
                  return [
                    _c(
                      "wizard-step",
                      {
                        attrs: {
                          tab: tab,
                          "step-size": _vm.stepSize,
                          transition: _vm.transition,
                          index: index,
                          isLastStep: _vm.isLastStep,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.navigateToTab(index)
                          },
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.navigateToTab(index)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "active-step" },
                            slot: "active-step",
                          },
                          [
                            !_vm.isLastStep
                              ? _c("i", {
                                  staticClass:
                                    "feather icon-chevrons-right next-button",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.navigateToTab(index + 1)
                                    },
                                  },
                                })
                              : _vm._e(),
                            tab.icon
                              ? _c("i", {
                                  staticClass: "wizard-icon",
                                  class: tab.icon,
                                })
                              : _c("i", { staticClass: "wizard-icon" }, [
                                  _vm._v(_vm._s(index + 1)),
                                ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
                {
                  tab: tab,
                  index: index,
                  navigateToTab: _vm.navigateToTab,
                  stepSize: _vm.stepSize,
                  transition: _vm.transition,
                }
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "wizard-tab-content" },
          [_vm._t("default", null, null, _vm.slotProps)],
          2
        ),
      ]),
      !_vm.hideButtons
        ? _c(
            "div",
            { staticClass: "wizard-card-footer clearfix" },
            [
              _vm._t(
                "footer",
                function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "wizard-footer-left" },
                      [
                        _vm.displayPrevButton
                          ? _c(
                              "span",
                              {
                                attrs: { role: "button", tabindex: "0" },
                                on: {
                                  click: _vm.prevTab,
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.prevTab.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._t(
                                  "prev",
                                  function () {
                                    return [
                                      _c(
                                        "wizard-button",
                                        {
                                          style: _vm.fillButtonStyle,
                                          attrs: { disabled: _vm.loading },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.backButtonText) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  null,
                                  _vm.slotProps
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._t(
                          "custom-buttons-left",
                          null,
                          null,
                          _vm.slotProps
                        ),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "wizard-footer-right" },
                      [
                        _vm._t(
                          "custom-buttons-right",
                          null,
                          null,
                          _vm.slotProps
                        ),
                        _vm.isLastStep
                          ? _c(
                              "span",
                              {
                                attrs: { role: "button", tabindex: "0" },
                                on: {
                                  click: _vm.nextTab,
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.nextTab.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._t(
                                  "finish",
                                  function () {
                                    return [
                                      _c(
                                        "wizard-button",
                                        { style: _vm.fillButtonStyle },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.finishButtonText) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  null,
                                  _vm.slotProps
                                ),
                              ],
                              2
                            )
                          : _c(
                              "span",
                              {
                                attrs: { role: "button", tabindex: "0" },
                                on: {
                                  click: _vm.nextTab,
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.nextTab.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._t(
                                  "next",
                                  function () {
                                    return [
                                      _c(
                                        "wizard-button",
                                        {
                                          style: _vm.fillButtonStyle,
                                          attrs: { disabled: _vm.loading },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.nextButtonText) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  null,
                                  _vm.slotProps
                                ),
                              ],
                              2
                            ),
                      ],
                      2
                    ),
                  ]
                },
                null,
                _vm.slotProps
              ),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }