import Rest from '../Rest'

export default class ClassService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/classes'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  teacherClasses(teacherId: Number) {
    return this.get(`/teacher/${teacherId}`).then(data => {
      return data
    })
  }

  studentGrid(path, data) {
    return this.get(`/student/grid`, data)
  }

  createOrUpdateExtended(record: any) {
    if (
      !record.hasOwnProperty('id') ||
      typeof record['id'] === undefined ||
      record['id'] === null ||
      record['id'] === undefined
    ) {
      delete record['id']
      return this.createExtended(record)
    }
    return this.updateExtended(record)
  }

  createExtended(record: any) {
    return this.post('extended', record)
  }

  updateExtended(record: any) {
    return this.put(`extended/${this.getId(record)}`, record)
  }

  inviteStudents(classDisciplineId: Number, students: Array<any>) {
    return this.post(`invite_students/${classDisciplineId}`, {
      students: students
    })
  }

  subscribeClass(inviteCode: String) {
    return this.post(`/${inviteCode}/subscribe`)
  }

  unsubscribeClass(params) {
    return this.post('/unsubscribe', params)
  }

  checkInvitationCode(code: String) {
    return this.get('invitation/valid', { invitation_code: code})
  }

  fetchPeople(classId: Number) {
    return this.get(`${classId}/people`)
  }

}
